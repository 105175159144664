import * as authorities from '@/components/shared/constants/authority.constants'
import * as rewardTemplatesConstants from '../components/shared/constants/reward-templates.constants'
import { ORDER_CHECKOUT_ROUTE, SHOPPING_CART_ROUTE } from '@/router/route-name-constants'

export default [
  {
    path: '/order',
    component: () => import(/* webpackChunkName: "order" */ '@/views/RewardFirstOrder'),
    children: [
      {
        path: '/order',
        name: 'RewardFirstOrder',
        component: () => import(/* webpackChunkName: "orderPlaceOrder" */ '@/components/platform/orders/phys-order-flow/PlaceOrder'),
        meta: {
          segmentName: 'All Tango Catalog',
          authorities: [authorities.PLACE_ORDER_ACCESS, authorities.IS_LOGGED_IN],
          pageTitle: 'portalfrontendApp.coreOrder.placeOrder.title'
        }
      },
      {
        path: '/order/explore-all-tango-catalog',
        name: 'ExploreAllTangoCatalog',
        component: () => import(/* webpackChunkName: "orderPlaceOrder" */ '@/components/platform/orders/phys-order-flow/ExploreAllTangoCatalog.vue'),
        meta: {
          segmentName: 'Place Order',
          authorities: [authorities.PLACE_ORDER_ACCESS, authorities.IS_LOGGED_IN],
          pageTitle: 'portalfrontendApp.coreOrder.placeOrder.title'
        }
      },
      {
        path: 'email-templates-simple',
        name: 'PhysOrderFlowSimpleDeliveryTemplate',
        props: true,
        component: () => import(/* webpackChunkName: "orderEmailTemplates" */ '@/components/platform/orders/phys-order-flow/email-templates/simple-flow/EmailTemplatePageSimpleFlow'),
        meta: {
          segmentName: 'Place Order Email Template',
          authorities: [authorities.PLACE_ORDER_ACCESS, authorities.IS_LOGGED_IN
          ],
          pageTitle: 'portalfrontendApp.coreOrder.placeOrder.title'
        }
      },
      {
        path: 'print-templates-simple/:brandKey',
        name: rewardTemplatesConstants.PRINT_TEMPLATE_PHYS_ORDER_FLOW_SIMPLE_ROUTE,
        props: true,
        component: () => import(/* webpackChunkName: "orderPrintTemplatesRedemption" */ '@/components/platform/orders/phys-order-flow/email-templates/simple-flow/EmailTemplatePageSimpleFlow'),
        meta: {
          segmentName: 'Place Order Print Template',
          authorities: [authorities.PLACE_ORDER_ACCESS, authorities.IS_LOGGED_IN
          ],
          pageTitle: 'portalfrontendApp.coreOrder.placeOrder.title',
          activeSubComponent: rewardTemplatesConstants.PRINT_TEMPLATE_REDEMPTION_SUB_COMPONENT
        }
      },
      {
        path: 'print-templates-simple/:brandKey/non-redemption',
        name: rewardTemplatesConstants.PRINT_TEMPLATE_PHYS_ORDER_FLOW_SIMPLE_NON_REDEMPTION_ROUTE,
        props: true,
        component: () => import(/* webpackChunkName: "orderPrintTemplatesNonRedemption" */ '@/components/platform/orders/phys-order-flow/email-templates/simple-flow/EmailTemplatePageSimpleFlow'),
        meta: {
          segmentName: 'Place Order Print Template',
          authorities: [authorities.PLACE_ORDER_ACCESS, authorities.IS_LOGGED_IN
          ],
          pageTitle: 'portalfrontendApp.coreOrder.placeOrder.title',
          activeSubComponent: rewardTemplatesConstants.PRINT_TEMPLATE_NON_REDEMPTION_SUB_COMPONENT
        }
      },
      {
        path: 'checkout',
        name: ORDER_CHECKOUT_ROUTE,
        component: () => import(/* webpackChunkName: "orderCheckout" */ '@/components/platform/orders/phys-order-flow/checkout/Checkout'),
        meta: {
          segmentName: 'Checkout',
          authorities: [authorities.PLACE_ORDER_ACCESS, authorities.IS_LOGGED_IN],
          pageTitle: 'portalfrontendApp.coreOrder.placeOrder.title'
        }
      },
      {
        path: 'cart',
        name: SHOPPING_CART_ROUTE,
        component: () => import(/* webpackChunkName: "orderShoppingCart" */ '@/components/platform/orders/phys-order-flow/cart/ShoppingCart'),
        meta: {
          segmentName: 'Cart',
          authorities: [authorities.PLACE_ORDER_ACCESS, authorities.IS_LOGGED_IN],
          pageTitle: 'portalfrontendApp.coreOrder.placeOrder.title'
        },
        props: true
      },
      {
        path: 'reward/:brandKey',
        name: 'Reward',
        component: () => import(/* webpackChunkName: "orderReward" */ '@/components/platform/orders/phys-order-flow/rewards/RewardPage'),
        meta: {
          authorities: [authorities.PLACE_ORDER_ACCESS, authorities.IS_LOGGED_IN],
          pageTitle: 'portalfrontendApp.placeOrder.rewards.rewardFirstOrdering.title'
        },
        props: true
      },
      {
        path: 'bulk-upload/:uploadId',
        name: 'BulkUpload',
        component: () => import(/* webpackChunkName: "bulkUploadErrors" */ '@/components/platform/orders/phys-order-flow/bulk-upload/BulkUploadPage'),
        meta: {
          authorities: [authorities.PLACE_ORDER_ACCESS, authorities.IS_LOGGED_IN],
          pageTitle: 'portalfrontendApp.placeOrder.rewards.rewardFirstOrdering.bulkUpload.pageTitle'
        }
      }
    ]
  }
]
