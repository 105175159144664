import { hasAuthority, hasAccessLevel } from '@/components/shared/security/authorized-plugin'
import {
  ACCOUNT_ACCESS,
  API_KEYS_MANAGE,
  QUALTRICS_API_KEYS_ACCESS,
  PLATFORM_ORDER_SETTINGS,
  RECIPIENT_WHITELIST_MANAGE,
  USER_ADMINISTRATION,
  AUTH_MANAGEMENT_MANAGE,
  QUALTRICS_API_KEYS_MANAGE,
  INTEGRATIONS_MANAGE
} from '@/components/shared/constants/authority.constants'
import {
  API_KEY_MANAGEMENT,
  INTEGRATION_CREDENTIALS,
  OAUTH_CLIENT_CRED_MGMT,
  ORDER_SETTINGS,
  QUALTRICS_SPLIT,
  RG_RECIPIENTS_WHITELIST_FEATURE
} from '@/components/shared/split/split-constants'
import { GET_TREATMENT_ENABLED } from '@/store/get-types'
import store from '@/store'
import { PLATFORM } from '@/components/shared/constants/access-levels.constants'

export const SettingsPages = Object.freeze({
  USERS: 'Users',
  ACCOUNTS: 'Accounts',
  RECIPIENTS: 'Recipients',
  ORDERS: 'Orders',
  TANGO_API: 'Tango API keys',
  QUALTRICS_API: 'Qualtrics API keys',
  OAUTH: 'OAuth client credentials',
  AUTHENTICATION: 'AUTHENTICATION',
  INTEGRATIONS: 'Integrations'
})

export const SettingsPaths = Object.freeze({
  USERS: '/users',
  ACCOUNTS: '/groups',
  RECIPIENTS: '/recipients-whitelist',
  ORDERS: '/settings/order-settings-management',
  TANGO_API: '/settings/api-key-management',
  QUALTRICS_API: '/settings/qualtrics-api-key-management',
  OAUTH: '/settings/oauth-client-credentials-management',
  AUTHENTICATION: '/authentication',
  INTEGRATIONS: '/integrations'
})

export function getSettingsPages () {
  const pages = []
  const isIntegrationSplitEnabled = store.getters[GET_TREATMENT_ENABLED](INTEGRATION_CREDENTIALS)

  if (hasAuthority(USER_ADMINISTRATION)) {
    pages.push(SettingsPages.USERS)
  }

  if (hasAuthority(ACCOUNT_ACCESS)) {
    pages.push(SettingsPages.ACCOUNTS) // also called "groups" page
  }

  if (store.getters[GET_TREATMENT_ENABLED](RG_RECIPIENTS_WHITELIST_FEATURE) && hasAuthority(RECIPIENT_WHITELIST_MANAGE)) {
    pages.push(SettingsPages.RECIPIENTS)
  }

  // This is the split and permission we use for required fields
  if (store.getters[GET_TREATMENT_ENABLED](ORDER_SETTINGS) && hasAuthority(PLATFORM_ORDER_SETTINGS)) {
    pages.push(SettingsPages.ORDERS)
  }

  if (hasAuthority(AUTH_MANAGEMENT_MANAGE) && hasAccessLevel(PLATFORM)) {
    pages.push(SettingsPages.AUTHENTICATION)
  }

  // check integration credentials split enabled
  // When the split is ON: Display the “Integrations” menu under “Team settings”
  if (
    isIntegrationSplitEnabled &&
    hasAccessLevel(PLATFORM) &&
    (hasAuthority(API_KEYS_MANAGE) || hasAuthority(QUALTRICS_API_KEYS_ACCESS) || hasAuthority(QUALTRICS_API_KEYS_MANAGE))
  ) {
    pages.push(SettingsPages.INTEGRATIONS)
  }

  if (!isIntegrationSplitEnabled && store.getters[GET_TREATMENT_ENABLED](QUALTRICS_SPLIT) && hasAuthority(QUALTRICS_API_KEYS_ACCESS)) {
    pages.push(SettingsPages.QUALTRICS_API)
  }

  if (!isIntegrationSplitEnabled && store.getters[GET_TREATMENT_ENABLED](API_KEY_MANAGEMENT) && hasAuthority(API_KEYS_MANAGE)) {
    pages.push(SettingsPages.TANGO_API)
  }

  if (!isIntegrationSplitEnabled && store.getters[GET_TREATMENT_ENABLED](OAUTH_CLIENT_CRED_MGMT) && hasAuthority(API_KEYS_MANAGE)) {
    pages.push(SettingsPages.OAUTH)
  }

  return pages
}

export function getSettingsPaths () {
  const paths = []
  const isIntegrationSplitEnabled = store.getters[GET_TREATMENT_ENABLED](INTEGRATION_CREDENTIALS)

  if (hasAuthority(USER_ADMINISTRATION)) {
    paths.push(SettingsPaths.USERS)
  }

  if (hasAuthority(ACCOUNT_ACCESS)) {
    paths.push(SettingsPaths.ACCOUNTS) // also called "groups" page
  }

  if (store.getters[GET_TREATMENT_ENABLED](RG_RECIPIENTS_WHITELIST_FEATURE) && hasAuthority(RECIPIENT_WHITELIST_MANAGE)) {
    paths.push(SettingsPaths.RECIPIENTS)
  }

  // This is the split and permission we use for required fields
  if (store.getters[GET_TREATMENT_ENABLED](ORDER_SETTINGS) && hasAuthority(PLATFORM_ORDER_SETTINGS)) {
    paths.push(SettingsPaths.ORDERS)
  }

  if (hasAuthority(AUTH_MANAGEMENT_MANAGE) && hasAccessLevel(PLATFORM)) {
    paths.push(SettingsPaths.AUTHENTICATION)
  }

  // check integration credentials split enabled
  // When the split is ON: Display the “Integrations” menu under “Team settings”
  if (isIntegrationSplitEnabled && hasAuthority(QUALTRICS_API_KEYS_ACCESS) && hasAuthority(QUALTRICS_API_KEYS_MANAGE) && hasAuthority(INTEGRATIONS_MANAGE) && hasAccessLevel(PLATFORM)) {
    paths.push(SettingsPaths.INTEGRATIONS)
  }
  if (!isIntegrationSplitEnabled && store.getters[GET_TREATMENT_ENABLED](QUALTRICS_SPLIT) && hasAuthority(QUALTRICS_API_KEYS_ACCESS)) {
    paths.push(SettingsPaths.QUALTRICS_API)
  }

  if (!isIntegrationSplitEnabled && store.getters[GET_TREATMENT_ENABLED](API_KEY_MANAGEMENT) && hasAuthority(API_KEYS_MANAGE)) {
    paths.push(SettingsPaths.TANGO_API)
  }

  if (!isIntegrationSplitEnabled && store.getters[GET_TREATMENT_ENABLED](OAUTH_CLIENT_CRED_MGMT) && hasAuthority(API_KEYS_MANAGE)) {
    paths.push(SettingsPaths.OAUTH)
  }

  return paths
}
