<template>
  <v-list
    id="team-settings"
    nav
    dense
  >
    <v-list-item
      v-for="control in getSettingsNavigation"
      :id="control.id"
      :key="control.id"
      :class="{ 'active': (isActive(control.route) || isActive(control.altRoute) )}"
      link
      @click="onSettingsClick(control.route)"
    >
      <v-list-item-title>
        {{ control.title }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>
<script>
import { getSettingsPages, SettingsPages } from '@/components/settings/settings'
import {
  TC_SETTINGS_PATH
} from '@/components/shared/constants/localstorage.constants'

export default {
  name: 'TeamSettingsMenu',
  computed: {
    getSettingsNavigation () {
      const pages = getSettingsPages()
      const items = []
      if (pages.includes(SettingsPages.USERS)) {
        items.push({
          id: 'users-settings',
          title: this.$t('settings.users.title'),
          description: this.$t('settings.users.description'),
          route: '/users',
          icon: 'mdi-account-multiple-outline'
        })
      }

      if (pages.includes(SettingsPages.ACCOUNTS)) {
        items.push({
          id: 'accounts-settings',
          title: this.$t('settings.accounts.title'),
          description: this.$t('settings.accounts.description'),
          route: '/groups',
          altRoute: '/accounts',
          icon: 'mdi-domain'
        })
      }

      if (pages.includes(SettingsPages.RECIPIENTS)) {
        items.push({
          id: 'recipients-settings',
          title: this.$t('settings.recipients.title'),
          description: this.$t('settings.recipients.description'),
          route: '/recipients-whitelist',
          icon: 'mdi-email-fast-outline'
        })
      }

      if (pages.includes(
        SettingsPages.ORDERS)) {
        items.push({
          id: 'order-settings',
          title: this.$t('settings.orderSettings.title'),
          description: this.$t('settings.orderSettings.description'),
          route: '/settings/order-settings-management',
          icon: 'mdi-order-bool-ascending-variant'
        })
      }

      if (pages.includes(SettingsPages.TANGO_API)) {
        items.push({
          id: 'api-keys-settings',
          title: this.$t('settings.apiKeys.title'),
          description: this.$t('settings.apiKeys.description'),
          route: '/settings/api-key-management',
          icon: 'mdi-shield-key-outline'
        })
      }

      if (pages.includes(SettingsPages.QUALTRICS_API)) {
        items.push({
          id: 'qualtrics-api-keys-settings',
          title: this.$t('settings.qualtricsApiKeys.title'),
          description: this.$t('settings.qualtricsApiKeys.description'),
          route: '/settings/qualtrics-api-key-management',
          icon: 'mdi-shield-key-outline'
        })
      }

      if (pages.includes(SettingsPages.OAUTH)) {
        items.push({
          id: 'oauth-client-credentials-management',
          title: this.$t('settings.oauthClientCredentials.title'),
          description: this.$t('settings.oauthClientCredentials.description'),
          route: '/settings/oauth-client-credentials-management',
          icon: 'mdi-shield-key-outline'
        })
      }

      if (pages.includes(SettingsPages.AUTHENTICATION)) {
        items.push({
          id: 'authentication',
          title: this.$t('settings.authentication.title'),
          description: this.$t('settings.authentication.description'),
          route: '/authentication',
          icon: 'mdi-shield-key-outline'
        })
      }

      if (pages.includes(SettingsPages.INTEGRATIONS)) {
        items.push({
          id: 'integrations',
          title: this.$t('settings.integrations.title'),
          description: this.$t('settings.integrations.description'),
          route: '/integrations',
          icon: 'mdi-shield-key-outline',
          altRoute: '/integrations/history'
        })
      }

      return items
    }
  },
  methods: {
    isActive (path) {
      return path === this.$route.path
    },
    onSettingsClick (route) {
      sessionStorage.setItem(TC_SETTINGS_PATH, route)
      this.$router.push({ path: route })
    }
  }
}
</script>

<style lang="scss" scoped>
#team-settings{
  padding: 0 !important;
  margin-top: 0;
  .v-list-item{
    padding-left:20px !important;
  }
}
</style>
