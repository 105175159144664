import { hasAuthority } from '../shared/security/authorized-plugin'
import * as authorities from '../shared/constants/authority.constants'
import store from '@/store'
import { GET_TREATMENT_ENABLED } from '@/store/get-types'
import {
  QUALTRICS_SPLIT,
  REWARD_HISTORY,
  RG_8577_GENERATE_NEW_REPORTS
} from '@/components/shared/split/split-constants'
import {
  getSettingsPages,
  getSettingsPaths
} from '@/components/settings/settings'
import * as events from '@/components/shared/segment/track-side-navigation'
import {
  TC_SETTINGS_PATH
} from '@/components/shared/constants/localstorage.constants'
import { isSplitEnabled } from '@/components/shared/split/split'

const accountUrl = 'https://help.rewardsgenius.com/en/articles/3419941-account-and-user-management-faq'
const fundingUrl = 'https://help.rewardsgenius.com/en/articles/3269079-funding-faq'

export const SettingsPath = '/settings'

const teamSettingsAuthorities = [authorities.USER_ADMINISTRATION, authorities.PLACE_ORDER_ACCESS, authorities.ACCOUNT_ACCESS, authorities.FUNDING_MANAGE, authorities.RECIPIENT_WHITELIST_MANAGE, authorities.PLATFORM_ORDER_SETTINGS, authorities.API_KEYS_MANAGE, authorities.QUALTRICS_API_KEYS_ACCESS, authorities.AUTH_MANAGEMENT_MANAGE]
const NavigationControls = [
  {
    chevron: false,
    icon: 'mdi-send-circle-outline',
    iconName: 'menu-place-order',
    translationKey: 'sidebar.navigation.placeOrder',
    get routePath () {
      return '/order'
    },
    authorities: [authorities.PLACE_ORDER_ACCESS],
    trackEventName: events.PLACE_ORDER_NAV_CLICKED,
    enabled: true
  },
  {
    chevron: false,
    icon: 'mdi-folder-outline',
    iconName: 'menu-order-history',
    translationKey: 'sidebar.navigation.orderHistory',
    get routePath () {
      if (store.getters[GET_TREATMENT_ENABLED](REWARD_HISTORY)) {
        return '/orders/line-item-history'
      } else {
        return '/orders'
      }
    },
    authorities: [authorities.ORDER_HISTORY_ACCESS, authorities.INDIVIDUAL_ORDER_HISTORY_ACCESS],
    trackEventName: events.ORDERS_NAV_CLICKED,
    enabled: true
  },
  {
    chevron: false,
    icon: 'mdi-email-outline',
    iconName: 'menu-templates',
    translationKey: 'sidebar.navigation.templates',
    routePath: '/email-templates',
    authorities: [authorities.EMAIL_TEMPLATES_ACCESS],
    trackEventName: events.TEMPLATES_NAV_CLICKED,
    enabled: true
  },
  {
    chevron: false,
    icon: 'mdi-currency-usd',
    iconName: 'menu-funding',
    translationKey: 'sidebar.navigation.funding',
    get routePath () {
      if (hasAuthority(authorities.FUNDING_MANAGE)) {
        return '/funding'
      } else {
        return '/funding/history'
      }
    },
    authorities: [authorities.FUNDING_ACCESS, authorities.FUNDING_MANAGE],
    trackEventName: events.FUNDING_NAV_CLICKED,
    enabled: true
  },
  {
    chevron: false,
    icon: 'mdi-chart-box-outline',
    iconName: 'menu-reports',
    translationKey: 'sidebar.navigation.reports',
    get routePath () {
      if (hasAuthority(authorities.ORDER_REPORT_MANAGE)) {
        return isSplitEnabled(RG_8577_GENERATE_NEW_REPORTS) ? '/reporting' : 'reports'
      } else {
        return '/reports-generated'
      }
    },
    authorities: [authorities.ORDER_REPORT_MANAGE, authorities.ORDER_REPORT_ACCESS],
    trackEventName: events.REPORTS_NAV_CLICKED,
    enabled: true
  },
  {
    chevron: false,
    icon: 'mdi-vector-link',
    iconName: 'menu-qualtrics',
    translationKey: 'sidebar.navigation.qualtrics',
    routePath: '/qualtrics-research',
    authorities: [authorities.INTEGRATIONS_ACCESS, authorities.INTEGRATIONS_MANAGE],
    trackEventName: events.QUALTRICS_NAV_CLICKED,
    get enabled () {
      return Boolean(store.getters[GET_TREATMENT_ENABLED](QUALTRICS_SPLIT))
    }
  },
  {
    chevron: true,
    icon: 'mdi-account-multiple-outline',
    iconName: 'menu-settings',
    translationKey: 'sidebar.navigation.settings',
    get routePath () {
      return getSettingsPath()
    },
    authorities: teamSettingsAuthorities,
    trackEventName: events.TEAM_SETTINGS_NAV_CLICKED,
    get enabled () {
      return getSettingsPages().length > 0
    }
  }
]

export function getSettingsPath () {
  const paths = getSettingsPaths()
  let path = paths[0].toLowerCase()
  const savedTeamSettings = sessionStorage.getItem(TC_SETTINGS_PATH)
  if (savedTeamSettings && paths.indexOf(savedTeamSettings) !== -1) {
    path = savedTeamSettings
  }
  return path
}

const Disclaimers = [
  {
    href: 'https://www.tangocard.com/legal/terms-of-service',
    target: '_blank',
    text: 'sidebar.branding.tos',
    key: 'disclaimer-tos'
  },
  {
    href: 'https://www.tangocard.com/legal/privacy-notice',
    target: '_blank',
    text: 'sidebar.branding.privacy',
    key: 'disclaimer-privacy'
  },
  {
    href: 'https://www.tangocard.com/legal/licenses/',
    target: '_blank',
    text: 'sidebar.branding.licenses',
    key: 'disclaimer-licenses'
  }
]

const RedirectLookupTable = [
  {
    path: '/home',
    href: 'https://help.rewardsgenius.com/en/collections/402472-getting-started'
  },
  {
    path: '/order',
    href: 'https://help.rewardsgenius.com/en/articles/3948961-ordering-faq'
  },
  {
    path: '/orders',
    href: 'https://help.rewardsgenius.com/en/articles/3747219-order-history-and-reports-faq'
  },
  {
    path: '/reports',
    href: 'https://help.rewardsgenius.com/en/articles/3747219-order-history-and-reports-faq'
  },
  {
    path: '/funding',
    href: fundingUrl
  },
  {
    path: '/funding/history',
    href: fundingUrl
  },
  {
    path: '/qualtrics-research',
    href: 'https://help.rewardsgenius.com/en/articles/2427154-faq-qualtrics-x-rewards-genius-integration'
  },
  {
    path: '/users',
    href: 'https://help.rewardsgenius.com/en/articles/3419941-account-and-user-management-faq'
  },
  {
    path: '/groups',
    href: accountUrl
  },
  {
    path: '/users',
    href: accountUrl
  },
  {
    path: '/recipients-whitelist',
    href: accountUrl
  },
  {
    path: '/email-templates',
    href: 'https://help.rewardsgenius.com/en/articles/3419942-email-templates-faq'
  },
  {
    path: '/print-templates',
    href: 'https://help.rewardsgenius.com/en/collections/402445-reward-templates'
  }
]
export {
  NavigationControls,
  Disclaimers,
  RedirectLookupTable
}
