import { recipientProperties, Recipient } from '@/dto/order/recipient/Recipient'
import { FulfillmentType } from '@/dto/order/FulfillmentType'
import { DeliveryType } from '@/dto/order/DeliveryType'

export const EMAIL_PROPERTY = 'email'
export const MOBILE_NUMBER_PROPERTY = 'mobileNumber'
export const DELIVERY_TO = 'deliveryTo'

export const recipientDigitalProperties = [
  ...recipientProperties,
  EMAIL_PROPERTY,
  MOBILE_NUMBER_PROPERTY
]

export class RecipientDigital extends Recipient {
  constructor (args) {
    const {
      [EMAIL_PROPERTY]: email,
      [MOBILE_NUMBER_PROPERTY]: phone,
      [DELIVERY_TO]: property,
      ...superProps
    } = args || {}

    super(superProps)
    this[EMAIL_PROPERTY] = email || null
    this[MOBILE_NUMBER_PROPERTY] = phone || null
    this.fulfillmentType = FulfillmentType.DIGITAL
    this[DELIVERY_TO] = phone || email || null
    this.deliveryType = phone ? DeliveryType.MOBILE : DeliveryType.EMAIL
  }

  equals (otherRecipientDigital) {
    return recipientDigitalProperties.every(prop => this[prop] === otherRecipientDigital[prop])
  }

  toJSON () {
    const recipient = super.toJSON()
    return {
      ...recipient,
      [EMAIL_PROPERTY]: this[EMAIL_PROPERTY],
      [MOBILE_NUMBER_PROPERTY]: this[MOBILE_NUMBER_PROPERTY],
      [DELIVERY_TO]: this[DELIVERY_TO],
      fulfillmentType: this.fulfillmentType
    }
  }
}
