<template>
  <validation-observer
    ref="observer"
    v-slot="observer"
    data-test="observer"
  >
    <tc-dialog
      keep-in-dom
      :value="showDialog && !showAddCreditCardDialog"
      :title="$t('portalfrontendApp.coreFunding.dialog.title')"
      :cancel-button-text="cancelButtonText"
      :confirm-button-text="confirmButtonText"
      :is-confirm-button-disabled="!isDialogValid(observer)"
      :is-loading="processingFunding"
      data-test="tcDialog"
      @cancel="closeDialog"
      @confirm="handleSubmit"
    >
      <loading
        v-if="loading"
        class="pt-9 pb-4"
        :visible="loading"
      />
      <div v-if="!loading">
        <dl>
          <dt>
            {{ $t('portalfrontendApp.coreFunding.dialog.accountHeader') }}
          </dt>
          <dd>
            <div
              v-if="showAccountHeader"
              class="funding-dialog-title font-weight-medium pb-2"
            >
              {{ targetAccount.accountName }} - {{ accountBalance }}
              <v-tooltip
                id="account-tooltip"
                right
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="funding-account-tooltip"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                {{ $t('portalfrontendApp.coreFunding.dialog.accountSwitcherTooltip') }}
              </v-tooltip>
            </div>
          </dd>
        </dl>

        <div class="toggle-group w-100">
          <v-btn
            class="toggle-button"
            :class="{ 'toggle-selected': depositFundsTabSelected }"
            data-testid="deposit-funds-button"
            @click="depositFundsTabSelected = true"
          >
            <v-icon class="mr-2">
              mdi-plus-circle-outline
            </v-icon>
            {{ $t('portalfrontendApp.coreFunding.dialog.depositFunds') }}
          </v-btn>
          <v-btn
            class="toggle-button"
            :class="{ 'toggle-selected': !depositFundsTabSelected }"
            data-testid="transfer-funds-button"
            @click="depositFundsTabSelected = false"
          >
            <v-icon class="mr-2">
              mdi-swap-horizontal
            </v-icon>
            {{ $t('portalfrontendApp.coreFunding.dialog.transferFunds') }}
          </v-btn>
        </div>

        <div
          class="pt-5"
        >
          <div v-if="depositFundsTabSelected">
            <validation-observer
              ref="depositObserver"
            >
              <deposit-funds-tab
                ref="depositFundsTab"
                :ach-accounts="achAccounts"
                :credit-cards="creditCards"
                :selected-customer-account="targetAccount"
                :current-platform="currentPlatform"
                @add-bank-account="$emit('add-bank-account')"
                @add-credit-card="showAddCreditCardDialog = true"
                @processing-funding="processingFunding = true"
                @finished-funding="handleDepositFundsCompleted"
                @close-dialog="closeDialog"
                @open-dialog="openDialog"
              />
            </validation-observer>
          </div>
          <div v-else>
            <div
              v-if="!accounts.length"
              class="empty-funding"
            >
              {{ $t('portalfrontendApp.coreFunding.dialog.noAccounts') }}
            </div>
            <div v-else>
              <validation-observer
                ref="transferObserver"
              >
                <transfer-funds-tab
                  ref="transferFundsTab"
                  :current-account="targetAccount"
                  :available-accounts="accounts"
                  @processing-funding="(processing) => processingFunding = processing"
                  @close-dialog="closeDialog"
                />
              </validation-observer>
            </div>
          </div>
        </div>
      </div>

      <add-new-credit-card
        v-if="showAddCreditCardDialog"
        :show-dialog="showAddCreditCardDialog"
        @close-dialog="closeAddCreditCardDialog"
      />
    </tc-dialog>
  </validation-observer>
</template>

<script>
import TcDialog from '@/components/shared/tc-dialog/TcDialog.vue'
import { mapActions, mapGetters } from 'vuex'
import TransferFundsTab from '@/components/platform/funding/add-funds-dialog/TransferFundsTab.vue'
import { GET_CUSTOMER_ACCOUNTS, GET_PLATFORM } from '@/store/get-types'
import { ACTIVE } from '@/components/shared/constants/account-statuses.constants'
import { ValidationObserver } from 'vee-validate'
import Loading from '@/components/shared/Loading.vue'
import DepositFundsTab from '@/components/platform/funding/add-funds-dialog/DepositFundsTab'
import { FUNDING_DIALOG_OPENED } from '@/components/shared/segment/track-funding'
import AddNewCreditCard
  from '@/components/platform/funding/credit-card/dialog/AddNewCreditCard.vue'
import { FUNDING_MANAGE } from '@/components/shared/constants/authority.constants'
import { isEmpty as _isEmpty } from 'lodash'
import { SET_CUSTOMER_ACCOUNTS } from '@/store/action-types'

export default {
  name: 'AddFundsDialog',
  components: {
    AddNewCreditCard,
    DepositFundsTab,
    Loading,
    TransferFundsTab,
    TcDialog,
    ValidationObserver
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    selectedCustomerAccount: {
      type: Object,
      default () {
        return {
          accountName: '',
          platformClientId: '',
          accountIdentifier: ''
        }
      }
    },
    accountBalance: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      creditCards: [],
      achAccounts: [],
      accounts: [],
      items: [
        this.$t('portalfrontendApp.coreFunding.dialog.depositFunds'),
        this.$t('portalfrontendApp.coreFunding.dialog.transferFunds')
      ],
      loading: true,
      predicate: 'createdDate',
      processingFunding: false,
      showAddCreditCardDialog: false,
      targetAccount: null,
      // true for deposit funds, false for transfer funds
      depositFundsTabSelected: true
    }
  },
  computed: {
    hasFundingMethods () {
      return !!(this.creditCards.length || this.achAccounts.length)
    },
    cancelButtonText () {
      return this.loading ? '' : this.$t('portalfrontendApp.coreFunding.dialog.cancel')
    },
    confirmButtonText () {
      if (this.loading) {
        return ''
      } else if (this.depositFundsTabSelected && this.hasFundingMethods) {
        return this.$t('portalfrontendApp.coreFunding.dialog.depositTabConfirm')
      } else if (!this.depositFundsTabSelected && this.accounts.length) {
        return this.$t('portalfrontendApp.coreFunding.dialog.transferTabConfirm')
      }
      return ''
    },
    showAccountHeader () {
      return !!(this.targetAccount?.accountName && this.accountBalance)
    },
    ...mapGetters({
      currentPlatform: GET_PLATFORM,
      getCustomerAccounts: GET_CUSTOMER_ACCOUNTS
    })
  },
  watch: {
    selectedCustomerAccount (newSelectedCustomerAccount) {
      // The account refreshes when a funding event successfully processes to show the new account balance. In that
      // case we don't want to reload the dialog data as the dialog is about to close.
      if (!this.processingFunding && !_isEmpty(newSelectedCustomerAccount)) {
        this.loadDialogData()
      }
    },
    showDialog (opened) {
      if (opened) {
        this.$segment.track(FUNDING_DIALOG_OPENED)
        this.loadDialogData()
      }
    }
  },
  methods: {
    ...mapActions({
      setCustomerAccounts: SET_CUSTOMER_ACCOUNTS
    }),
    isDialogValid (observer) {
      if (observer.pristine || observer.pending) return false

      if (this.depositFundsTabSelected) {
        if (this.$refs.depositObserver) {
          const { ctx } = this.$refs.depositObserver
          return ctx.valid
            ? ctx.valid
            : false
        }
      } else {
        if (this.$refs.transferObserver) {
          const { ctx } = this.$refs.transferObserver
          return ctx.valid
            ? ctx.valid
            : false
        }
      }
      return false
    },
    handleSubmit () {
      if (this.depositFundsTabSelected) {
        this.$refs.depositFundsTab?.submitForm()
      } else {
        this.$refs.transferFundsTab?.submitForm()
      }
    },
    closeDialog () {
      this.$emit('close-dialog')
    },
    openDialog () {
      this.$emit('open-dialog')
    },
    async loadDialogData () {
      if (!this.$hasAuthority(FUNDING_MANAGE)) {
        return
      }

      this.loading = true

      // Why is this necessary?
      // The selectedCustomerAccount prop isn't guaranteed to have the fields we need to add funds
      // but the customer accounts we fetch does. So all we're doing here is taking the account
      // identifier from the prop and locating it in the list of accounts.
      // Then we use it throughout this component and all children instead of the prop.
      await this.loadCustomerAccounts()
      this.targetAccount = this.getTargetAccountFromCustomerAccounts()

      await Promise.all([
        this.loadCreditCards(),
        this.loadAchAccounts()
      ])
      this.loading = false
    },
    async loadCreditCards () {
      const options = {
        params: {
          sort: this.sort()
        }
      }
      try {
        const { data } = await this.$http
          .get(`api/creditCards/platforms/accounts/${this.targetAccount.accountIdentifier}`, options)
        this.creditCards = data
      } catch (error) {
        console.log('Loading credit cards error: ' + error)
      }
    },
    async loadAchAccounts () {
      try {
        const { data } = await this.$http.get(`api/ach/subscriptions/${this.targetAccount.accountID}`)
        this.achAccounts = data
      } catch (error) {
        console.log('Loading ACH error: ' + error)
      }
    },
    async loadCustomerAccounts () {
      try {
        await this.setCustomerAccounts()
        const accountsResponse = this.getCustomerAccounts
        this.accounts = accountsResponse.filter(account => account.status === ACTIVE)
      } catch (error) {
        console.log('loading accounts errors: ' + error)
        this.$toast(this.$httpError(error, this.$t(`${this.i18nPrefix}.getAccountsError`)), 'danger')
      }
    },
    getTargetAccountFromCustomerAccounts () {
      // one of these should always be set
      const accountIdentifier = this.selectedCustomerAccount.accountIdentifier ?? this.selectedCustomerAccount.identifier
      // this accounts list is already filtered so no need for that here
      const foundAccount = this.accounts.find((account) => account.accountIdentifier === accountIdentifier)
      // we *should* always find it but fall back to the selected one just in case (and log an error for good measure)
      if (!foundAccount) {
        console.error(`Unable to find account identifier ${accountIdentifier} in list of accounts`)
        return this.selectedCustomerAccount
      }
      return foundAccount
    },
    sort () {
      return this.predicate + ',' + (this.reverse ? 'asc' : 'desc')
    },
    handleDepositFundsCompleted () {
      this.processingFunding = false
    },
    async closeAddCreditCardDialog () {
      this.showAddCreditCardDialog = false
      await this.loadDialogData()
    }
  }
}
</script>

<style scoped lang="scss">

.funding-dialog-title {
  font-size: var(--t-font-size-1);
  font-weight: var(--t-font-weight-5);
  color: var(--t-color-text);
}

.empty-funding {
  padding: 1rem 2rem;
}

.v-tabs-bar {
  .v-tab:not(.v-tab--active),
  .v-tab--disabled {
    color: var(--t-color-text) !important; // Not loving using the !important, but it's scoped, so...
  }
}

// Vuetify default styling does a carousel with the tabs on smaller displays, we never want that
::v-deep {
  .v-slide-group__prev,
  .v-slide-group__next {
    display: none !important;
  }
  .v-slide-group__wrapper .v-tabs-bar__content {
    width: 100% !important;
  }
}

.v-tab {
  width: 50%;
  border: var(--t-border-size-1) solid var(--t-color-border);
  border-right: unset;
  border-radius: var(--t-radius-1) 0 0 var(--t-radius-1);
  text-transform: initial;

  &:last-child {
    border: var(--t-border-size-1) solid var(--t-color-border);
    border-left: unset;
    border-radius: 0 var(--t-radius-1) var(--t-radius-1) 0;
  }

  &.v-tab--active {
    color: var(--t-color-primary);
    background: var(--t-color-primary-weakest);
    border: var(--t-border-size-1) solid var(--t-color-primary-weak);
  }
}

.funding-account-tooltip {
  vertical-align: bottom;
}

.toggle-group {
  justify-content: space-between;

  .toggle-button {
    flex-grow: 1;
  }
}
</style>
