<template>
  <div class="d-flex">
    <v-menu
      v-if="!disabled"
      rounded
      offset-y
    >
      <template #activator="{ on, attrs }">
        <a
          id="add-payment-method-link"
          href=""
          v-bind="attrs"
          v-on="on"
          @click.prevent
        >
          {{ $t(`${i18nPrefix}.addPaymentMethod`) }}
        </a>
      </template>
      <v-list
        id="payment-options-list"
        dense
      >
        <v-list-item
          id="options-item-add-credit-card"
          link
          :disabled="!isAccountActive"
          @click="$emit('add-credit-card')"
        >
          <v-list-item-title>
            {{ $t(`${i18nPrefix}.creditCard`) }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="isAchSplitEnabled && isAccountCurrencyUSD && !accountHasCredit"
          link
          :disabled="!isAccountActive"
          @click="navigateToAddBankAccount"
        >
          <v-list-item-title>
            {{ $t(`${i18nPrefix}.bankAccount`) }}
          </v-list-item-title>
        </v-list-item>

        <v-divider class="my-1" />

        <v-list-item
          id="all-payment-options-link"
          href="https://help.rewardsgenius.com/s/article/RewardsGeniusFundingOptions"
          link
          target="_blank"
        >
          <v-list-item-title class="list-item-title-with-icon">
            {{ $t(`${i18nPrefix}.allPaymentOptions`) }}
            <v-icon
              class="m-0"
              color="black"
            >
              mdi-open-in-new
            </v-icon>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Disabled state - show tooltip -->
    <v-tooltip
      v-else
      top
    >
      <template #activator="{ on, attrs }">
        <p
          class="disabled-link"
          aria-disabled="true"
          v-bind="attrs"
          v-on="on"
          @click.prevent
        >
          {{ $t(`${i18nPrefix}.addPaymentMethod`) }}
        </p>
      </template>
      <span>{{ $t(`${i18nPrefix}.disabledDropdown`) }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { RG_ACH_DRAW } from '@/components/shared/split/split-constants'
import { GET_SELECTED_CUSTOMER_ACCOUNT } from '@/store/get-types'
import { mapGetters } from 'vuex'
import { USD_CURRENCY_CODE } from '@/components/platform/funding/ach/constants'
import { FUNDING_ROUTE } from '@/router/route-name-constants'

export default {
  name: 'PaymentMethodDetailsAddPaymentDropdown',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      i18nPrefix: 'portalfrontendApp.placeOrder.paymentMethod.addPaymentMethodDropdown',
      isAchSplitEnabled: this.$isSplitEnabled(RG_ACH_DRAW)
    }
  },
  computed: {
    ...mapGetters({
      selectedCustomerAccount: GET_SELECTED_CUSTOMER_ACCOUNT
    }),
    isAccountCurrencyUSD () {
      return this.selectedCustomerAccount?.currencyCode === USD_CURRENCY_CODE
    },
    accountHasCredit () {
      return this.selectedCustomerAccount.creditLimit > 0
    },
    isAccountActive () {
      return this.selectedCustomerAccount?.active
    }
  },
  methods: {
    navigateToAddBankAccount () {
      this.$router.push({
        name: FUNDING_ROUTE,
        params: {
          showAchDialog: true
        }
      })
      // The above code doesn't work on the /funding page because we're already there so alternatively
      // also fire an event
      this.$emit('add-bank-account')
    }
  }
}
</script>

<style scoped>
  ::v-deep .v-list-item__title {
    font-weight: 400 !important;
  }

  #add-payment-method-link {
    align-self: flex-start;
    font-weight: var(--t-font-weight-5);
    text-decoration: underline;
  }

  /* Override the global link override which keeps changing the color of the text */
  #all-payment-options-link {
    color: var(--t-color-text) !important;
  }
  .disabled-link {
    color: var(--t-color-text-weak);
    cursor: not-allowed;
    text-decoration: underline;
  }

  .list-item-title-with-icon {
    align-items: center;
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }

  .v-list-item--disabled .list-item-title-with-icon .v-icon {
    opacity: 0.3;
  }
</style>
