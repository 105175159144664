import { RewardItem } from '@/dto/order/RewardItem'
import { SelectedRewardItem } from '@/dto/order/SelectedRewardItem'
import { FulfillmentType } from '@/dto/order/FulfillmentType'
import { PhoneNumber } from '@/common/phone-number'
import { DeliveryType } from '@/dto/order/DeliveryType'

const DEFAULT_QUANTITY = 1

export default class SelectedRewardItemService {
  static getRewardTotal (items) {
    return items
      .filter(({ amount }) => this.isNumeric(amount))
      .reduce((total, { amount, quantity }) => total + Math.floor((amount * quantity) * 100), 0) / 100
  }

  static isNumeric (n) {
    return !isNaN(parseFloat(n)) && isFinite(n)
  }

  static mapRecipient (selectedRewardItemRow, deliveryType) {
    const rewardItem = selectedRewardItemRow.rewardItem || {}

    const deliveryTemplateProperties = selectedRewardItemRow.isPhysical
      ? {
          etid: 'E000000', // etid is a required field for now
          message: selectedRewardItemRow.deliveryTemplate.markupCustomization.message,
          personalizationToName: selectedRewardItemRow.deliveryTemplate.markupCustomization.toName,
          personalizationFromName: selectedRewardItemRow.deliveryTemplate.markupCustomization.fromName,
          letterHeadTitle: selectedRewardItemRow.deliveryTemplate.markupCustomization.letterHeadTitle,
          deliveryTemplateIdentifier: selectedRewardItemRow.deliveryTemplate.deliveryTemplateIdentifier
        }
      : {
          etid: selectedRewardItemRow.deliveryTemplate.deliveryTemplateIdentifier,
          message: selectedRewardItemRow.deliveryTemplate.markupCustomization.message,
          senderFirstName: selectedRewardItemRow.deliveryTemplate.markupCustomization.senderName,
          emailSubject: selectedRewardItemRow.deliveryTemplate.markupCustomization.subject
        }

    let recipientMobileNumber = selectedRewardItemRow.recipient.mobileNumber
    let recipientEmail = selectedRewardItemRow.recipient.email

    if (deliveryType === DeliveryType.MOBILE) {
      if (recipientMobileNumber) {
        recipientMobileNumber = PhoneNumber.parse(recipientMobileNumber).format()
        recipientEmail = null
      }
    } else {
      recipientMobileNumber = null
    }

    return {
      utid: selectedRewardItemRow.rewardItem.utid,
      fulfillmentType: rewardItem.fulfillmentType,
      amount: selectedRewardItemRow.amount,
      quantity: selectedRewardItemRow.quantity,
      currencyCode: selectedRewardItemRow.rewardItem.currencyCode,
      notes: selectedRewardItemRow.metadata.notes,
      recipientEmail,
      recipientMobileNumber,
      recipientFirstName: selectedRewardItemRow.recipient.firstName,
      recipientLastName: selectedRewardItemRow.recipient.lastName,
      recipientAddress: selectedRewardItemRow.recipient.address,
      rewardItemMetadata: rewardItem,
      isFileUpload: false,
      ...deliveryTemplateProperties,
      wyndhamLocationId: selectedRewardItemRow.wyndhamLocationId,
      wyndhamTourId: selectedRewardItemRow.wyndhamTourId,
      scheduledDeliveryTime: selectedRewardItemRow.scheduledDeliveryTime,
      shippingInfo: selectedRewardItemRow.shippingInfo
    }
  }

  static async mapSelectedRewardItemFromUploadLine (line, deliveryTemplateDto) {
    const itemMetadata = line.rewardItemMetadata || new RewardItem()

    const deliveryTemplate = itemMetadata.fulfillmentType === FulfillmentType.PHYSICAL
      ? {
          name: deliveryTemplateDto.name,
          deliveryTemplateIdentifier: deliveryTemplateDto.identifier,
          markupCustomization: {
            letterHeadTitle: line.letterHeadTitle,
            fromName: line.personalizationFromName,
            message: line.message,
            toName: line.personalizationToName
          }
        }
      : {
          name: deliveryTemplateDto.name,
          deliveryTemplateIdentifier: line.etid,
          markupCustomization: {
            senderName: line.senderFirstName,
            subject: line.emailSubject,
            message: line.message
          }
        }

    return new SelectedRewardItem({
      amount: line.amount,
      metadata: {
        notes: line.notes
      },
      quantity: line.quantity ?? DEFAULT_QUANTITY,
      recipient: {
        firstName: line.recipientFirstName,
        lastName: line.recipientLastName,
        email: line.recipientEmail,
        mobileNumber: line.recipientMobileNumber,
        address: line.recipientAddress,
        fulfillmentType: itemMetadata.fulfillmentType,
        deliveryType: itemMetadata.deliveryType
      },
      deliveryTemplate,
      rewardItem: itemMetadata,
      identifier: line.identifier,
      wyndhamLocationId: line.wyndhamLocationId,
      wyndhamTourId: line.wyndhamTourId,
      scheduledDeliveryTime: line.scheduledDeliveryTime,
      shippingInfo: line.shippingInfo
    })
  }

  static async mapSelectedRewardItem (line, deliveryTemplateDto) {
    const { metadata, fulfillmentType, monetaryAmountDto, deliveries } = line
    const recipientInfo = deliveries[0].deliveryMethod.recipientContactInformation
    const itemMetadata = {
      amountDetails: metadata.amountDetails,
      currencyCode: metadata.currencyCode,
      fulfillmentType,
      rewardImage: metadata.rewardImage,
      rewardName: metadata.rewardName,
      utid: metadata.utid,
      valueType: metadata.valueType,
      brandKey: metadata.brandKey,
      rewardType: {
        rewardTypeID: metadata.rewardTypeID,
        description: metadata.description
      },
      attributeNames: metadata.attributeNames
    }

    const deliveryTemplate = fulfillmentType === 'PHYSICAL'
      ? {
          name: deliveryTemplateDto.name,
          deliveryTemplateIdentifier: deliveryTemplateDto.identifier,
          markupCustomization: {
            letterHeadTitle: line.letterHeadTitle,
            fromName: line.personalizationFromName,
            message: line.message,
            toName: line.personalizationToName
          }
        }
      : {
          name: deliveryTemplateDto.name,
          deliveryTemplateIdentifier: deliveries[0].deliveryMethod.templateId,
          markupCustomization: {
            senderName: deliveries[0].deliveryMethod.senderContactInformation.firstName,
            subject: deliveries[0].deliveryMethod.emailSubject,
            message: deliveries[0].deliveryMethod.message
          }
        }

    return new SelectedRewardItem({
      amount: monetaryAmountDto.amount,
      metadata: { notes: line.notes },
      quantity: line.quantity ?? DEFAULT_QUANTITY,
      recipient: {
        firstName: recipientInfo.firstName,
        lastName: recipientInfo.lastName,
        email: recipientInfo.emailAddress,
        mobileNumber: recipientInfo.phoneNumber,
        address: {
          streetLine1: recipientInfo.streetLine1,
          streetLine2: recipientInfo.streetLine2,
          city: recipientInfo.city,
          stateOrProvince: recipientInfo.stateOrProvince,
          country: recipientInfo.country,
          postalCode: recipientInfo.postalCode
        },
        fulfillmentType,
        deliveryType: deliveries[0].deliveryMethod.deliveryType
      },
      deliveryTemplate,
      rewardItem: itemMetadata,
      identifier: metadata.portalLineItemIdentifier,
      wyndhamLocationId: line.wyndhamLocationId,
      wyndhamTourId: line.wyndhamTourId,
      scheduledDeliveryTime: line.scheduledDeliveryTime,
      shippingInfo: line.shippingInfo
    })
  }
}
