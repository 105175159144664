<template>
  <form>
    <v-row>
      <v-col class="text-left p-0">
        <label for="transfer-from-select">
          {{ $t('portalfrontendApp.coreFunding.transferTab.transferFromLabel') }}
        </label>
        <validation-provider
          v-slot="{errors, failedRules, flags: { touched, dirty }}"
          ref="transferFrom"
          vid="transferFrom"
          immediate
          rules="required|differentAccount:transferTo|differentCurrency:transferTo|zeroBalanceSelect"
          name="transfer from"
          data-test="transferFromValidator"
        >
          <v-autocomplete
            id="transfer-from-select"
            v-model="formTransferFrom"
            :error-messages="touched || dirty ? getCustomMessage({errors, failedRules}) : []"
            :items="availableAccounts"
            dense
            outlined
            :item-text="(account) => `${account.accountName} - ${$moneyIntl(account.currentBalance, account.currencyCode)}`"
            return-object
            :no-data-text="$t('portalfrontendApp.coreFunding.transferTab.selectPlaceholder')"
            :placeholder="$t('portalfrontendApp.coreFunding.transferTab.selectPlaceholder')"
            data-test="transferFrom"
            @blur="trackSegmentValidationEvent(errors)"
          />
        </validation-provider>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-left p-0">
        <label for="transfer-to-select">
          {{ $t('portalfrontendApp.coreFunding.transferTab.transferToLabel') }}
        </label>
        <validation-provider
          v-slot="{errors, failedRules, flags: { touched, dirty }}"
          ref="transferTo"
          vid="transferTo"
          immediate
          rules="required|differentAccount:transferFrom|differentCurrency:transferFrom"
          name="transfer to"
          data-test="transferToValidator"
        >
          <v-autocomplete
            id="transfer-to-select"
            v-model="formTransferTo"
            :error-messages="touched || dirty ? getCustomMessage({errors, failedRules}) : []"
            :items="availableAccounts"
            dense
            outlined
            :item-text="(account) => `${account.accountName} - ${$moneyIntl(account.currentBalance, account.currencyCode)}`"
            return-object
            :no-data-text="$t('portalfrontendApp.coreFunding.transferTab.selectPlaceholder')"
            :placeholder="$t('portalfrontendApp.coreFunding.transferTab.selectPlaceholder')"
            data-test="transferTo"
            @blur="trackSegmentValidationEvent(errors)"
          />
        </validation-provider>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-left p-0">
        <label for="transfer-amount">
          {{ $t('portalfrontendApp.coreFunding.transferTab.transferAmountLabel') }}
        </label>
        <validation-provider
          v-slot="{errors, failedRules, flags: { touched, dirty }}"
          ref="transferAmount"
          vid="transferAmount"
          immediate
          name="transfer amount"
          :rules="`required|zeroBalanceInput:transferFrom|noTransferFromAccount:transferFrom|max_value:${maxTransferLimit}|min_value:1`"
          data-test="amountValidator"
        >
          <v-text-field
            id="transfer-amount"
            v-model="formTransferAmount"
            type="number"
            dense
            outlined
            :error-messages="touched || dirty ? getCustomMessage({errors, failedRules}) : []"
            @blur="trackSegmentValidationEvent(errors)"
          />
        </validation-provider>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-left p-0">
        <label for="transfer-notes">
          {{ $t('portalfrontendApp.coreFunding.transferTab.transferNotesLabel') }}
        </label>
        <validation-provider
          v-slot="{errors, failedRules, flags: { touched, dirty }}"
          ref="transferNotes"
          vid="transferNotes"
          immediate
          name="transfer notes"
          rules="required"
          data-test="notesValidator"
        >
          <v-text-field
            id="transfer-notes"
            v-model="formTransferNotes"
            dense
            outlined
            :error-messages="touched || dirty ? getCustomMessage({errors, failedRules}) : []"
            @blur="trackSegmentValidationEvent(errors)"
          />
        </validation-provider>
      </v-col>
    </v-row>

    <v-row class="font-weight-medium total-row">
      <v-col class="text-left p-0">
        {{ $t('portalfrontendApp.coreFunding.transferTab.totalTransferAmountLabel') }}
      </v-col>
      <v-col class="text-right p-0">
        {{ totalTransferAmount }}
      </v-col>
    </v-row>
  </form>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { UPDATE_THE_SELECTED_ACCOUNT } from '@/store/action-types'
import { FUNDING_DIALOG_VALIDATION_ERROR, FUNDING_TRANSFER_SUCCESS } from '@/components/shared/segment/track-funding'
import { mapActions } from 'vuex'
import { SUCCESS, ERROR } from '@/components/shared/alert/snack-constants'

export default {
  name: 'TransferFundsTab',
  components: {
    ValidationProvider
  },
  props: {
    currentAccount: {
      type: Object,
      required: true
    },
    availableAccounts: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      formTransferFrom: null,
      formTransferTo: this.currentAccount,
      formTransferAmount: null,
      formTransferNotes: '',
      currencyCode: this.currentAccount.currencyCode,
      achBasePrefix: 'portalfrontendApp.coreAch.snackBarAlertMessages'
    }
  },
  computed: {
    totalTransferAmount () {
      return this.$moneyIntl(this.formTransferAmount, this.currencyCode)
    },
    i18nPrefix () {
      return 'portalfrontendApp.coreFunding.home.transfer'
    },
    transferTabPrefix () {
      return 'portalfrontendApp.coreFunding.transferTab'
    },
    customErrorMessages () {
      return {
        max_value: this.$t(`${this.transferTabPrefix}.validationMessages.maxValue`, { maxAmount: this.maxTransferLimit }),
        min_value: this.$t(`${this.transferTabPrefix}.validationMessages.minValue`, { minAmount: '$1' })
      }
    },
    maxTransferLimit () {
      return this.formTransferFrom?.currentBalance
    }
  },
  beforeMount () {
    const differentAccountErrorMessage = this.$t(`${this.transferTabPrefix}.validationMessages.differentAccount`)
    const differentCurrencyErrorMessage = this.$t(`${this.transferTabPrefix}.validationMessages.differentCurrency`)
    const zeroBalanceErrorMessage = this.$t(`${this.transferTabPrefix}.validationMessages.zeroBalance`)
    const missingTransferFrom = this.$t(`${this.transferTabPrefix}.validationMessages.noTransferFromAccount`)

    this.$validator.extend('differentAccount', {
      getMessage (field, args) {
        return differentAccountErrorMessage
      },
      validate (value, [otherValue]) {
        return value?.accountIdentifier !== otherValue?.accountIdentifier
      }
    }, { hasTarget: true })

    this.$validator.extend('differentCurrency', {
      getMessage () {
        return differentCurrencyErrorMessage
      },
      validate (value, [otherValue]) {
        return value?.currencyCode === otherValue?.currencyCode
      }
    }, { hasTarget: true })

    this.$validator.extend('zeroBalanceSelect', {
      getMessage () {
        return zeroBalanceErrorMessage
      },
      validate (value) {
        return value?.currentBalance !== 0
      }
    })

    this.$validator.extend('zeroBalanceInput', {
      getMessage () {
        return zeroBalanceErrorMessage
      },
      validate (value, [targetSelect]) {
        return targetSelect?.currentBalance !== 0
      }
    }, { hasTarget: true })

    this.$validator.extend('noTransferFromAccount', {
      getMessage () {
        return missingTransferFrom
      },
      validate (value, [targetSelect]) {
        return !!targetSelect
      }
    }, { hasTarget: true })
  },
  methods: {
    ...mapActions({
      updateSelectedAccount: UPDATE_THE_SELECTED_ACCOUNT
    }),
    async submitForm () {
      try {
        this.$emit('processing-funding', true)
        this.$snack(this.$t(`${this.achBasePrefix}.processingFundingRequest`), true)
        await this.$http.post('api/funding/transfer-funds', {
          fromAccountIdentifier: this.formTransferFrom.accountIdentifier,
          fromGroupIdentifier: this.formTransferFrom.customerIdentifier,
          toAccountIdentifier: this.formTransferTo.accountIdentifier,
          toGroupIdentifier: this.formTransferTo.customerIdentifier,
          amount: this.formTransferAmount,
          notes: this.formTransferNotes
        })
        await this.updateSelectedAccount()
        this.$segment.track(FUNDING_TRANSFER_SUCCESS)
        this.$snack(this.$t(`${this.achBasePrefix}.fundingRequestSubmitted`), false, SUCCESS)
      } catch (error) {
        console.error(error)
        this.$snack(this.$t(`${this.achBasePrefix}.fundingRequestFailed`), false, ERROR, -1)
      } finally {
        this.$emit('processing-funding', false)
        this.$emit('close-dialog')
      }
    },
    getCustomMessage ({ errors = [], failedRules = {} } = {}) {
      const [failedRule] = Object.keys(failedRules)
      const [firstError] = errors
      const { [failedRule]: errorMessage } = this.customErrorMessages
      return errorMessage || firstError
    },
    trackSegmentValidationEvent (errors) {
      if (errors.length) { this.$segment.track(FUNDING_DIALOG_VALIDATION_ERROR) }
    }
  }
}
</script>

<style scoped>

.total-row {
  color: var(--t-color-text);
}

</style>
