<template>
  <!-- Note: We don't want the dialog to close when clicking outside it - apparently Vuetify only does that if you
       add the @click:outside event so don't do that here. We do want the Esc key to close it. -->
  <v-dialog
    v-if="keepInDom || show"
    v-model="show"
    :content-class="contentClasses"
    :fullscreen="fullscreenDialog"
    :max-width="maxWidth"
    :persistent="persistent"
    scrollable
    @keydown.esc="closeDialog"
  >
    <v-card
      id="dialog-container"
      class="space-between-children"
    >
      <img
        v-if="showHeaderImage"
        class="header-image pt-1 pb-2 mb-0 pull-left"
        :class="{ 'medium': headerImageMedium, 'large': headerImageLarge}"
        :src="headerImage"
        :alt="headerAlt"
      >

      <v-btn
        v-if="!persistent && !hideCloseButton"
        class="close-button btn-text"
        color="secondary"
        icon
        @click="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-title
        v-if="title"
        class="tc-dialog-title pa-0"
        :class="{ 'shortened': !persistent && !fullWidthTitle, 'fs-unmask': unmask }"
      >
        {{ title }}
        <slot name="title-pills" />
      </v-card-title>
      <div class="mb-0">
        <slot name="back" />
      </div>
      <div
        v-if="showLoadingSpinner"
        class="loading-container"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          size="50"
        />
      </div>
      <template v-else>
        <v-card-text class="pa-0">
          <slot />
        </v-card-text>

        <v-card-actions
          v-if="cancelButtonText || confirmButtonText"
          class="dialog-footer pa-0"
        >
          <div class="button-container">
            <div
              v-if="!fullWidthButtons"
              class="left-buttons"
            >
              <!-- Any kind of content outside the standard cancel/confirm button - aligned to the left -->
              <slot name="leftBtn" />
            </div>
            <div
              class="right-buttons"
              :class="{ 'full-width': fullWidthButtons, 'separate-buttons': separateButtons }"
            >
              <v-btn
                v-if="cancelButtonText"
                class="rounded-lg"
                :class="{ 'fs-unmask': unmask }"
                outlined
                depressed
                data-testid="cancel"
                right
                @click="closeDialog"
              >
                {{ cancelButtonText }}
              </v-btn>
              <v-btn
                v-if="confirmButtonText"
                class="btn-primary"
                :class="{ 'full-width': fullWidthButtons, 'ml-2': !fullWidthButtons, 'fs-unmask': unmask }"
                data-testid="confirm"
                :loading="isLoading"
                :disabled="isConfirmButtonDisabled"
                right
                @click="$emit('confirm')"
              >
                {{ confirmButtonText }}
              </v-btn>
            </div>
          </div>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

// "Small" devices are this value and up (we want the dialog to be fullscreen below this threshold)
const BOOTSTRAP_SMALL_THRESHOLD = 576
const DEFAULT_ICON = require('@/assets/images/tango-logo.svg')

export default {
  name: 'TcDialog',
  props: {
    // Dialog implementations that use TcDialog, should be able to keep the VDialog in the DOM if they want to
    keepInDom: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    cancelButtonText: {
      type: String,
      default: ''
    },
    confirmButtonText: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: String,
      default: '480'
    },
    isConfirmButtonDisabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    showLoadingSpinner: {
      type: Boolean,
      default: false
    },
    showHeaderImage: {
      type: Boolean,
      default: false
    },
    headerImage: {
      type: String,
      default: DEFAULT_ICON
    },
    headerAlt: {
      type: String,
      default: 'Tango'
    },
    headerImageMedium: {
      type: Boolean,
      default: false
    },
    fullWidthButtons: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: false
    },
    fullWidthTitle: {
      type: Boolean,
      default: false
    },
    unmask: {
      type: Boolean,
      default: false
    },
    separateButtons: {
      type: Boolean,
      default: false
    },
    headerImageLarge: {
      type: Boolean,
      default: false
    },
    classes: {
      type: Array,
      required: false,
      default: () => []
    },
    hideCloseButton: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      windowWidth: window.innerWidth,
      defaultClass: 'tc-dialog'
    }
  },

  computed: {
    fullscreenDialog () {
      return this.windowWidth < BOOTSTRAP_SMALL_THRESHOLD
    },
    // This component implements v-model so must have a "value" prop and emit an "input" event
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    contentClasses () {
      let classesString = `${this.defaultClass} `
      if (this.classes?.length) {
        classesString += this.classes.join(' ')
      }
      return classesString
    }
  },

  mounted () {
    window.addEventListener('resize', this.handleWindowResize)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.handleWindowResize)
  },

  methods: {
    closeDialog () {
      if (!this.persistent || this.fullWidthTitle) {
        this.$emit('cancel')
      }
    },
    handleWindowResize: debounce(function () {
      this.windowWidth = window.innerWidth
    }, 250)
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/modules/_borders.scss";
@import "~@/components/shared/tc-dialog/TcDialog.scss";

/* v-dialog styling requires global scope since the dialog attaches to the root element */
.v-dialog.tc-dialog {
  border-radius: var(--t-radius-3);
  max-height: calc(100% - 16vh - (#{$tc-dialog-vertical-padding} * 2)) !important;
  position: absolute;
  top: 8vh;

  &.v-dialog--fullscreen {
    border-radius: 0;
    max-height: 100% !important;
    top: 0;
  }
}
</style>

<style scoped lang="scss">
@import "~@/components/shared/tc-dialog/TcDialog.scss";

// vuetify sets negative margin for these rows which we were offsetting with padding - so just reset it for the dialog
// so we don't have to keep offsetting it for every form input in all our dialogs
::v-deep .row {
  margin: 0 !important;
}

// use ID here to out-!important vuetify's !important
#dialog-container {
  padding: $tc-dialog-vertical-padding $tc-dialog-horizontal-padding !important;
}

.header-image {
  align-self: flex-start;
  height: $tc-dialog-header-height;

  &.medium {
    width: $tc-dialog-medium-header-width;
    height: auto;
  }
  &.large{
    width: $tc-dialog-large-header-width;
    height: auto;
    margin:auto;
  }
}

.close-button {
  height: $tc-dialog-header-height !important;
  position: absolute;
  right: $tc-dialog-horizontal-padding;
  z-index: 1;
}

.tc-dialog-title {
  font-size: var(--t-font-size-2) !important;
  word-break: break-word;
  justify-content: space-between;

  &.shortened {
    width: 80%; // stay away from our close button
  }
}

/* Any children of this element should have 20px spacing between them */
.space-between-children>*:not(:last-child) {
  margin-bottom: $tc-dialog-vertical-padding;
}

.dialog-footer {
  .button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
.separate-buttons{
  justify-content: space-between;
  width:100%;
  display:flex;
}

.full-width {
  width: 100%;
}

.loading-container {
  margin: 120px auto;
}
</style>
