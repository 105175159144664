<template>
  <div
    class="tc-banner-message mt-2 mb-0"
    :class="`message-${bannerMessage.type}`"
  >
    <div
      v-if="bannerMessage.title"
      class="font-weight-medium p-0 mr-0 text-center icon-col"
    >
      <v-icon
        medium
      >
        {{ bannerMessage.icon || 'mdi-information-outline' }}
      </v-icon>
    </div>
    <div
      class="p-0 text-left message-content"
    >
      <p
        v-if="bannerMessage.title"
        id="banner-message-title"
        class="font-weight-medium font-size-1"
      >
        {{ bannerMessage.title.trim() }}
      </p>
      <div
        v-if="bannerMessage.description"
        id="banner-message-description"
        class="font-size-12"
      >
        <div v-sanitize="bannerMessage.description.trim()" />
      </div>
      <div
        v-if="hasBottomButtons"
        class="banner-bottom-btns"
      >
        <v-btn
          v-for="(button, index) in bottomButtons"
          :key="index"
          v-ripple="false"
          text
          class="tc-banner-bottom-btn font-weight-medium font-size-1 pa-0 ma-0"
          @click="button.onClick"
        >
          {{ button.text }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { BannerMessageTypes } from '@/components/authentication/enum/BannerMessageType'

export default {
  name: 'TcBannerMessage',
  props: {
    bannerMessage: {
      type: Object,
      default: () => ({
        title: {
          type: String,
          default: ''
        },
        description: {
          type: String,
          default: ''
        },
        type: {
          type: String,
          default: BannerMessageTypes.WARNING
        }
      })
    },
    bottomButtons: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    hasBottomButtons () {
      return this.bottomButtons.length > 0
    }
  }
}
</script>

<style scoped lang="scss">

.tc-banner-message{
  background-color: var(--t-color-primary-weakest);
  border-radius:0.5rem;
  padding: 1rem;
  margin: 1.5rem auto;
  white-space:normal;
  gap: 0.75rem;
  display: flex;

  &.message-warning{
    background-color: var(--t-color-status-warning-weak);
  }
  &.message-error{
    background-color: var(--t-color-status-danger-weakest);
  }
  &.message-info{
    background-color: var(--t-color-primary-weakest);
  }
  &.message-success{
    background-color: var(--t-color-status-success-weakest);
  }
  .v-icon {
    color:var(--t-color-text);
  }
  ::v-deep a{
    font-weight: var(--t-font-weight-5) !important;
    color: var(--t-color-text-link) !important;;
    text-decoration: underline !important;;
  }
}

.icon-col{
  flex-grow: 0;
}

.message-content{
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

#banner-message-title {
  // Need to out-!important vuetify overrides
  margin-bottom: 0 !important;
}

.login-error{
  padding-left:32px;
}

.banner-bottom-btns {
  display: flex;
  gap: 0.5rem;
}

.tc-banner-bottom-btn {
  color: var(--t-color-text);
  cursor: pointer;
  text-decoration: underline;

  ::v-deep span {
    padding: 0 0.5rem;
  }

  &:first-child {
    // Offset the above 0.5rem padding so that it lines up on the left side
    margin-left: -0.5rem !important;
  }
}
</style>
