import { FulfillmentType } from '@/dto/order/FulfillmentType'
import { AmountDetails } from '@/dto/order/AmountDetails'
import { RewardType } from '@/dto/order/RewardType'

export class RewardItem {
  _amountDetails
  _rewardType

  constructor ({
    utid = null,
    rewardName = null,
    rewardImage = null,
    fulfillmentType = FulfillmentType.DIGITAL,
    currencyCode = null,
    valueType = null,
    brandKey = null,
    rewardType = {},
    amountDetails,
    attributeNames = null
  } = {}) {
    this.utid = utid
    this.rewardName = rewardName
    this.rewardImage = rewardImage
    this.currencyCode = currencyCode
    this.fulfillmentType = fulfillmentType
    this.valueType = valueType
    this.brandKey = brandKey
    this.rewardType = rewardType
    this.amountDetails = amountDetails
    this.attributeNames = attributeNames
  }

  set amountDetails (amount) {
    this._amountDetails = amount instanceof AmountDetails ? amount : new AmountDetails(amount)
  }

  get amountDetails () {
    return this._amountDetails
  }

  set rewardType (rewardType) {
    this._rewardType = rewardType instanceof RewardType ? rewardType : new RewardType(rewardType)
  }

  get rewardType () {
    return this._rewardType
  }

  get isRewardLink () {
    return this.rewardType.isRewardLink
  }

  get isPhysical () {
    return this.fulfillmentType === FulfillmentType.PHYSICAL
  }

  get isPrintableRewardLink () {
    return this.isRewardLink && this.isPhysical
  }

  // Used to persist data to localStorage
  toJSON () {
    const { utid, rewardName, rewardImage, currencyCode, fulfillmentType, valueType, amountDetails, brandKey, rewardType, attributeNames } = this
    return { utid, rewardName, rewardImage, currencyCode, fulfillmentType, valueType, amountDetails, brandKey, rewardType: rewardType.toJSON(), attributeNames: Array.from(new Set(attributeNames)) }
  }
}
