import store from '@/store/index'
import * as authorities from '@/components/shared/constants/authority.constants'
import { hasAnyAuthority, hasAuthority } from '@/components/shared/security/authorized-plugin'
import { QUALTRICS_SPLIT, REWARD_HISTORY } from '@/components/shared/split/split-constants'
import { GET_TREATMENT_ENABLED } from '@/store/get-types'
import { getSettingsPaths } from '@/components/settings/settings'

/**
 * Retrieve the first accessible route a user can access.
 * @returns {string} first accessible route
 */
export const getFirstAccessibleRoute = () => {
  let homeRoute = '/settings/user'
  if (hasAuthority(authorities.PLACE_ORDER_ACCESS)) {
    homeRoute = '/order'
  } else if (hasAnyAuthority([authorities.ORDER_HISTORY_ACCESS, authorities.INDIVIDUAL_ORDER_HISTORY_ACCESS])) {
    if (store.getters[GET_TREATMENT_ENABLED](REWARD_HISTORY)) {
      homeRoute = '/orders/line-item-history'
    } else {
      homeRoute = '/orders'
    }
  } else if (hasAuthority(authorities.FUNDING_MANAGE)) {
    homeRoute = '/funding'
  } else if (hasAuthority(authorities.FUNDING_ACCESS)) {
    homeRoute = '/funding/history'
  } else if (hasAuthority(authorities.EMAIL_TEMPLATES_ACCESS)) {
    homeRoute = '/email-templates'
  } else if (hasAuthority(authorities.ORDER_REPORT_MANAGE)) {
    homeRoute = '/reports'
  } else if (hasAuthority(authorities.ORDER_REPORT_ACCESS)) {
    homeRoute = '/reports-generated'
  } else if (store.getters[GET_TREATMENT_ENABLED](QUALTRICS_SPLIT) && hasAnyAuthority([authorities.INTEGRATIONS_ACCESS, authorities.INTEGRATIONS_MANAGE])) {
    homeRoute = '/qualtrics-research'
  } else {
    // check settings paths inside here because getSettingsPaths is sort of expensive
    const accessibleSettingsPaths = getSettingsPaths()
    if (accessibleSettingsPaths.length) {
      homeRoute = accessibleSettingsPaths[0]
    }
  }

  return homeRoute
}

/**
 * This function is used by sidebar navigation to determine whether to mark a nav item as active or not. It takes a
 * route (e.g. /orders/line-items) and returns what sidebar nav path is considered the parent of the route.
 *
 * This is necessary because we're not particularly great at nesting routes in RG.
 *
 * @param route the route, e.g. /funding-history
 * @returns {string} the sidebar route that is considered the parent of the route (e.g. "/settings" is the parent of the
 *   "/users" route
 */
export const getParentRoute = (route) => {
  const routeChunks = route.split('/')
  if (routeChunks.length < 2) {
    return route
  }

  switch (routeChunks[1]) {
    case 'order':
    case 'catalog':
      return '/order'
    case 'orders':
    case 'order-complete':
      return store.getters[GET_TREATMENT_ENABLED](REWARD_HISTORY) ? '/orders/line-item-history' : '/orders'
    case 'email-templates':
    case 'print-templates':
      return '/email-templates'
    case 'funding':
      return hasAuthority(authorities.FUNDING_MANAGE) ? '/funding' : '/funding/history'
    case 'reports':
    case 'reports-generated':
      return hasAuthority(authorities.ORDER_REPORT_MANAGE) ? '/reports' : '/reports-generated'
    case 'qualtrics-research':
      return '/qualtrics-research'
    case 'settings':
    case 'users':
    case 'groups':
    case 'accounts':
    case 'saml':
    case 'authentication':
    case 'recipients-whitelist':
    case 'integrations':
    case 'roles':
      return '/settings'
  }

  return route
}
