const AccountsUserManagement = () => import(/* webpackChunkName: "AccountsUserManagement" */ '@/components/platform/accounts-user-management/AccountsUserManagement')
const UserManagement = () => import(/* webpackChunkName: "Users" */ '@/components/platform/accounts-user-management/users/UserManagement.vue')

export default [
  {
    path: '/users',
    name: 'Users',
    component: UserManagement,
    meta: {
      segmentName: 'User Management',
      authorities: ['USER_ADMINISTRATION', 'IS_LOGGED_IN'],
      pageTitle: 'userManagement.home.title',
      activeSubComponent: 'users'
    },
    props: (route) => ({ query: route.query.q })
  },
  {
    path: '/recipients-whitelist',
    name: 'Recipients Whitelist',
    component: AccountsUserManagement,
    meta: {
      segmentName: 'Recipients Whitelist',
      authorities: ['IS_LOGGED_IN', 'RECIPIENT_WHITELIST_MANAGE'],
      pageTitle: 'recipientsWhitelist.pageTitle',
      activeSubComponent: 'recipients whitelist'
    }
  },
  {
    path: '/users/role-management',
    name: 'RoleManagement',
    component: () => import(/* webpackChunkName: "roleManagement" */ '@/components/platform/accounts-user-management/role-management/RoleManagement'),
    meta: {
      segmentName: 'Role Management',
      authorities: ['ROLE_MANAGEMENT_MANAGE', 'IS_LOGGED_IN'],
      pageTitle: 'userManagement.roleManagement.title'
    }
  },
  {
    path: '/roles',
    name: 'Roles',
    component: () => import(/* webpackChunkName: "roleManagement" */ '@/components/platform/accounts-user-management/roles/Roles'),
    meta: {
      segmentName: 'Roles',
      authorities: ['ROLE_MANAGEMENT_MANAGE', 'IS_LOGGED_IN'],
      pageTitle: 'userManagement.roleManagement.title'
    }
  }
]
